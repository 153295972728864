import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Firewalls = lazy(() => import("./Firewalls"));
const FirewallDetails = lazy(() => import("./instancedetails/FirewallDetails"));
const FirewallCreate = lazy(() => import("./createfirewall/FirewallCreate"));

const pagesRoutes = [
  {
    path: "/firewalls/list",
    component: Firewalls,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_read"],
  },
  {
    path: "/firewalls/:project_id/:physical_region_id/:firewall_id",
    component: FirewallDetails,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_read"],
  },
  {
    path: "/firewall/create",
    component: FirewallCreate,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_write"],
  },
];

export default pagesRoutes;
