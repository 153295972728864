import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";
import React from "react";

const Dnsmanagement = lazy(() => import("./Dnsmanagement"));
const DnsRecords = lazy(() => import("./records/DnsRecords"));

const pagesRoutes = [
  {
    path: "/dnsmanagement",
    component: Dnsmanagement,
    auth: authRoles.admin,
  },
  {
    path: "/dnsrecordsmanagment/:domain_name",
    component: DnsRecords,
    auth: authRoles.admin,
  },
  {
    path: "/dnsmanagmentnotified/:domain_name",
    component: (props) => {
      return <DnsRecords {...props} isDomainCreated={true} />;
    },
    auth: authRoles.admin,
  },
];

export default pagesRoutes;
