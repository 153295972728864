import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppContext from "./appContext";
import history from "@history";
import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import { renderRoutes } from "react-router-config";
import RootRoutes from "./RootRoutes";
import { Loading } from "@omnicloud";

import "../styles/app/app.scss";
import "../styles/custom/style.scss";

function App() {
  return (
    <AppContext.Provider value={{ routes }}>
      <Provider store={Store}>
        <Suspense fallback={<Loading></Loading>}>
          <Router history={history}>{renderRoutes(RootRoutes)}</Router>
        </Suspense>
      </Provider>
    </AppContext.Provider>
  );
}

export default App;
