import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const OmniCompute = lazy(() => import("./OmniCompute"));

const omniRoutes = [
  {
    path: "/omnicompute",
    component: OmniCompute,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_read"],
  },
];

export default omniRoutes;
