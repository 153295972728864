let keycloak = null;

const KeycloakHolder = {
  setKeycloak: (newVal) => (keycloak = newVal),
  getKeycloak: () => keycloak,
  getKeycloakToken: () => {
    keycloak
      .updateToken(30)
      .then((refreshed) => {
        if (refreshed) {
          console.log("THE TOKEN HAS BEEN UPDATED");
        } else {
          console.log("STILL USING THE OLD TOKEN");
        }
      })
      .catch((err) => {
        console.log("ERROR UPDATING TOKEN", err);
      });

    return keycloak.token;
  },
};

Object.freeze(KeycloakHolder);

export default KeycloakHolder;
