import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Backups = lazy(() => import("./Backups"));
const RestoreToVolume = lazy(() =>
  import("./backupstab/createvolume/RestoreToVolume")
);
const BackupScheduleCreate = lazy(() =>
  import("./backupschedulestab/BackupScheduleCreate")
);
const AssignedVMsList = lazy(() =>
  import("./backupschedulestab/manageassignedvms/AssignedVMsList")
);
const AssignVMtoBackupSchedule = lazy(() =>
  import("./backupschedulestab/manageassignedvms/AssignVMtoBackupSchedule")
);

const pagesRoutes = [
  {
    path: "/backups/list",
    component: Backups,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_read"],
  },
  {
    path: "/backups/:project_id/:physical_region_id/:backup_id/restore-to-volume",
    component: RestoreToVolume,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_write"],
  },
  {
    path: "/backups/backupschedule/create",
    component: BackupScheduleCreate,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_write"],
  },
  {
    path: "/backups/backupschedule/edit/:backupschedule_id",
    component: BackupScheduleCreate,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_write"],
  },
  {
    path: "/backups/backupschedule/:backupschedule_id/virtualmachines",
    component: AssignedVMsList,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_read"],
  },
  {
    path: "/backups/backupschedule/:backupschedule_id/assignvms",
    component: AssignVMtoBackupSchedule,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_create"],
  },
];

export default pagesRoutes;
