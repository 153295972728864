import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Cdn = lazy(() => import("./Cdn"));

const pagesRoutes = [
  {
    path: "/cdn",
    component: Cdn,
    auth: authRoles.admin,
  },
];

export default pagesRoutes;
