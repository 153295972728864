import {NotificationManager} from "react-notifications";
import i18next from "i18next";

//NOTIFICATION ON CRUD OPERATIONS
export const create = (text) => {
  NotificationManager.success(i18next.t(`${text}`), "", 5000, () => {}, 1);
};

export const removed = (text) => {
  NotificationManager.success(i18next.t(`${text}`), "", 3000, () => {}, 1);
};

export const warning = (text) => {
  NotificationManager.warning(i18next.t(`${text}`), "", 3000, () => {}, 1);
};

export const error = (text) => {
  NotificationManager.error(i18next.t(`${text}`), "", 3000, () => {}, 1);
};

export const resErr = (text) => {
  NotificationManager.error(i18next.t(`${text}`), "", 3000, () => {}, 1);
};
export const srvErr = (errObj) => {
  const {title, message} = errObj;
  NotificationManager.error(i18next.t(`${message}`), title, 3000, () => {}, 1);
};

//FORM VALIDATION FOR DNS RECORDS
export const validIpAddressV4 = (data) => {
  const isValidIpAddressV4 = new RegExp(
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/g
  );
  return isValidIpAddressV4.test(data);
};

export const validIpAddressV6 = (data) => {
  const isValidIpAdressV6 = new RegExp(
    /^((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*::((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4}))*|((?:[0-9A-Fa-f]{1,4}))((?::[0-9A-Fa-f]{1,4})){7}$/g
  );
  return isValidIpAdressV6.test(data);
};

export const validHostName = (data) => {
  const isValidHostName = new RegExp(
    /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?)((\.[a-zA-Z0-9]{1,2}([-a-zA-Z0-9]{0,252}[a-zA-Z0-9])?)*)((\.[a-zA-Z]{2,63})?)$|@/gs
  );
  console.log(data);
  return isValidHostName.test(data);
};

export const validTopLevelDomain = (data) => {
  const isValidTopLevelDomain = new RegExp(
    /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?)((\.[a-zA-Z0-9]{1,2}([-a-zA-Z0-9]{0,252}[a-zA-Z0-9])?)*)((\.[a-zA-Z]{2,63})+)$/gs
  );
  return isValidTopLevelDomain.test(data);
};

export const isPositiveNumber = (data) => {
  console.log(data, !isNaN(parseInt(data)));
  return !isNaN(parseInt(data)) && data > 0;
};
