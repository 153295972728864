import React, { Component } from "react";
import i18next from "i18next";
import { Tab, Tabs, Nav } from "react-bootstrap";
import { ReactSVG } from "react-svg";
import axios from "axios";
import { getRegions } from "app/services/region";
import { COUNTRY_TO_FLAG } from "common/constants";

class RegionPicker extends Component {
  state = {
    regionsList: [],
  };

  getAllRegions = () => {
    getRegions(this.axiosCancelSource.token)
      .then((res) => {
        const sortedRegions = res.data.regions.sort((a, b) =>
          a.geo_region_name > b.geo_region_name
            ? 1
            : a.geo_region_name === b.geo_region_name
            ? a.country > b.country
            : a.country === b.country
            ? a.name > b.name
            : -1
        );

        const groupedRegions = sortedRegions.reduce((acc, value) => {
          if (!acc[value.geo_region_name]) {
            acc[value.geo_region_name] = [];
          }

          acc[value.geo_region_name].push(value);

          return acc;
        }, {});

        this.setState({ regionsList: groupedRegions });
        this.props.onRegionChange(
          groupedRegions[Object.keys(groupedRegions)[0]][0].id
        );
      })
      .catch((err) => {
        //...
        console.log(err.message);
      });
  };

  componentDidMount() {
    this.axiosCancelSource = axios.CancelToken.source();
    this.getAllRegions();
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  render() {
    let regionsList = this.state.regionsList;

    return (
      <React.Fragment>
        <div id="region" className="section-wrapper w-100">
          <p className="mb-4 section-title">{i18next.t("Choose Location")}</p>
          {Object.keys(regionsList).length === 0 && (
            <div className="d-flex justify-content-center">
              <div className="text-center loader-bubble loader-bubble-primary m-5"></div>
            </div>
          )}

          {Object.keys(regionsList).length > 0 && (
            <Tabs
              defaultActiveKey="allLocation"
              id="region-tab"
              className="mb-3"
            >
              <Tab eventKey="allLocation" title="All Locations">
                <Nav
                  variant="pills"
                  className="flex-row"
                  defaultActiveKey={
                    regionsList[Object.keys(regionsList)[0]][0].id
                  }
                >
                  {Object.keys(regionsList).length > 0 &&
                    Object.keys(regionsList).map((geoRegionName) =>
                      regionsList[geoRegionName].map((region) => (
                        <Nav.Item key={region.id}>
                          <Nav.Link
                            eventKey={region.id}
                            onClick={() => this.props.onRegionChange(region.id)}
                          >
                            <div className="card-picker d-flex align-items-center">
                              <img
                                src={`/assets/images/countries/${
                                  COUNTRY_TO_FLAG[region.country]
                                }`}
                                alt={region.country + " Flag"}
                              />
                              <div className="body-info">
                                <p className="m-0 title">{region.name}</p>
                                <p className="m-0 subtitle">{region.country}</p>
                              </div>
                              <ReactSVG
                                src="/assets/icons/CheckCircle.svg"
                                className="check"
                              ></ReactSVG>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      ))
                    )}
                </Nav>
              </Tab>
              {Object.keys(regionsList).map((geoRegionName) => (
                <Tab
                  key={geoRegionName}
                  eventKey={geoRegionName}
                  title={geoRegionName}
                >
                  <Nav variant="pills" className="flex-row">
                    {regionsList[geoRegionName].map((region) => (
                      <Nav.Item key={region.id}>
                        <Nav.Link
                          eventKey={region.id}
                          onClick={() => this.props.onRegionChange(region.id)}
                        >
                          <div className="card-picker d-flex align-items-center">
                            <img
                              src={`/assets/images/countries/${
                                COUNTRY_TO_FLAG[region.country]
                              }`}
                              alt={region.country + " Flag"}
                            />
                            <div className="body-info">
                              <p className="m-0 title">{region.name}</p>
                              <p className="m-0 subtitle">{region.country}</p>
                            </div>
                            <ReactSVG
                              src="/assets/icons/CheckCircle.svg"
                              className="check"
                            ></ReactSVG>
                          </div>
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Tab>
              ))}
            </Tabs>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default RegionPicker;
