import React from "react";
import { Redirect } from "react-router-dom";
import dashboardRoutes from "./views/dashboard/dashboardRoutes";
import vmInstancesRoutes from "./views/vminstances/vmInstancesRoutes";
import storageVolumesRoutes from "./views/storagevolumes/storageVolumesRoutes";
import firewallsRoutes from "./views/firewalls/firewallsRoutes";
import backupsRoutes from "./views/backups/BackupsRoutes";
import objectStorageRoutes from "./views/objectstorage/objectStorageRoutes";
import networkingRoutes from "./views/networking/NetworkingRoutes";
import loadBalancerRoutes from "./views/loadbalancer/loadBalancerRoutes";
import dnsmanagementRoutes from "./views/dnsmanagement/dnsmanagementRoutes";
import cdn from "./views/cdn/cdnRoutes";
import appMarketplace from "./views/appmarketplace/appMarketplaceRoutes";
import usageAndBilling from "./views/usageandbilling/usageAndBillingRoutes";
import userManagement from "./views/usermanagement/userManagementRoutes";
import omniRoutes from "./views/omnicompute/omniRoutes";

import sessionsRoutes from "./views/sessions/sessionsRoutes";
import AuthGuard from "./auth/AuthGuard";
import pagesRoutes from "./views/pages/pagesRoutes";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard/v1" />,
  },
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />,
  },
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: AuthGuard,
    routes: [
      ...dashboardRoutes,
      ...vmInstancesRoutes,
      ...storageVolumesRoutes,
      ...firewallsRoutes,
      ...backupsRoutes,
      ...objectStorageRoutes,
      ...networkingRoutes,
      ...loadBalancerRoutes,
      ...dnsmanagementRoutes,
      ...cdn,
      ...appMarketplace,
      ...usageAndBilling,
      ...userManagement,
      ...pagesRoutes,
      ...redirectRoute,
      ...omniRoutes,
      ...errorRoute,
    ],
  },
];

export default routes;
