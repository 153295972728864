import {lazy} from "react";
import {authRoles} from "app/auth/authRoles";

const UserManagement = lazy(() => import("./UserManagement"));
const UserPreferences = lazy(() => import("./UserPreferences"));

const SshKeys = lazy(() =>
  import("../../../app/views/sshkeys/SshKeys.jsx")
);

const pagesRoutes = [
  {
    path: "/user-management",
    component: UserManagement,
    auth: authRoles.admin,
    required_permissions: ["user_management_write"]
  },
  {
    path: "/user-preferences",
    component: UserPreferences,
    auth: authRoles.admin,
    required_permissions: ["user_management_write"]
  },
  {
    path: "/user-ssh-keys",
    component: SshKeys,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_write"]
  }
];

export default pagesRoutes;
