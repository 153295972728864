import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import AppContext from "app/appContext";
import OmniCloudLayout from "app/OmniCloudLayout/OmniCloudLayout";
import { flatMap } from "lodash";
import AuthInfoHolder, {
  getAllCustomerAccounts,
  getCustomerSpecificToken,
} from "./AuthService";
import KeycloakHolder from "./KeycloakService";
import { Loading } from "@omnicloud";
import axios from "axios";

class AuthGuard extends Component {
  constructor(props, context) {
    super(props);
    let { routes } = context;
    this.state = { authenticated: false, routes };
    KeycloakHolder.setKeycloak(window.Keycloak("/keycloak.json"));
    this.keycloak = KeycloakHolder.getKeycloak();
  }

  componentDidMount() {
    this.keycloak
      .init({
        onLoad: "login-required",
      })
      .then((authenticated) => {
        if (authenticated) {
          this.exchangeAccessTokenForCustomerSpecificToken();
        }
      });
    /**/
  }

  componentDidUpdate() {
    this.keycloak
      .updateToken(30)
      .then((refreshed) => {
        if (refreshed) {
          console.log("TOKEN HAS BEEN UPDATED");
          this.exchangeAccessTokenForCustomerSpecificToken();
        } else {
          console.log("STILL USING THE OLD TOKEN");
        }
      })
      .catch((err) => {
        console.log("ERROR UPDATING TOKEN", err);
      });
  }

  redirectRoute(props) {
    const { location, history } = props;
    const { pathname } = location;

    history.push({
      pathname: "/session/signin",
      state: { redirectUrl: pathname },
    });
  }

  exchangeAccessTokenForCustomerSpecificToken = () => {
    // first let's see how many customer accounts we have
    getAllCustomerAccounts(this.keycloak.token)
      .then((res) => {
        if (res.data.customer_accounts.length === 1) {
          // we can exchange the token for a customer specific token for the only possible customer account
          AuthInfoHolder.setCustomerAccountId(res.data.customer_accounts[0].id);
          getCustomerSpecificToken(
            res.data.customer_accounts[0].id,
            this.keycloak.token
          )
            .then((tokenRes) => {
              AuthInfoHolder.setCustomerSpecificToken(tokenRes.data.token);
              AuthInfoHolder.setPermissions(tokenRes.data.permissions);
              AuthInfoHolder.setProjects(tokenRes.data.projects);
              axios.defaults.headers.common["Authorization"] =
                "Bearer " + tokenRes.data.token;
              this.setState({ authenticated: true });
            })
            .catch((err) => {
              delete axios.defaults.headers.common["Authorization"];
              console.log(err);
            });
        } else if (res.data.customer_accounts.length > 1) {
          // we will need to choose one of many possible customer accounts
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let { route, location } = this.props;
    const { authenticated } = this.state;

    const routes_flatted = flatMap(this.state.routes, (item) => {
      if (item.routes) {
        return [...item.routes];
      }
      return [item];
    });

    const { pathname } = location;
    const matched_route = routes_flatted.find((r) => r.path === pathname);
    const hasAllPermissions =
      matched_route &&
      matched_route.required_permissions &&
      matched_route.required_permissions.length
        ? AuthInfoHolder.getPermissions().includes(
            matched_route.required_permissions[0]
          )
        : true;

    return authenticated ? (
      <Fragment>
        <OmniCloudLayout
          route={route}
          hasAllPermissions={hasAllPermissions}
        ></OmniCloudLayout>
      </Fragment>
    ) : (
      <Loading></Loading>
    );
  }
}
AuthGuard.contextType = AppContext;

export default withRouter(AuthGuard);
