import React, { Component } from "react";
import { Link } from "react-router-dom";
import { classList } from "@utils";
import DropDownMenuItem from "./DropDownMenuItem";
import i18next from "i18next";
import { ReactSVG } from "react-svg";

class DropDownMenu extends Component {
  state = {
    open: false,
    activeElement: null,
  };

  componentDidMount() {
    this.selectedNavItem();
  }

  selectedNavItem = () => {
    let currentURL = window.location.href,
      menuItems = this.props.menu,
      activeMenuElement = localStorage.getItem("activeMenuElement");

    for (let i = 0; i < menuItems.length; i++) {
      if (currentURL.indexOf(menuItems[i].path) > 0) {
        activeMenuElement = i;
      }
    }

    if (activeMenuElement !== null) {
      this.setState({ activeElement: activeMenuElement });
    } else {
      this.setState({ activeElement: 0 });
    }
  };

  onItemClick = (e, i, closeSidenav) => {
    e.preventDefault();

    if (closeSidenav === true) {
      this.props.handleMenuClose();
    }

    localStorage.setItem("activeMenuElement", i);
    this.setState({ activeElement: i });
  };

  renderLevels = (items) =>
    items.map((item, i) => {
      if (item.sub) {
        return (
          <DropDownMenuItem key={i} item={item}>
            {this.renderLevels(item.sub)}
          </DropDownMenuItem>
        );
      } else {
        return (
          <li
            key={i}
            className={classList({
              "nav-item": true,
              open: this.state.activeElement === i ? true : false,
            })}
            onClick={(e) => {
              this.onItemClick(e, i, item.closeSidenav);
            }}
          >
            {this.state.activeElement === i && (
              <span className="active-top"></span>
            )}
            <Link to={item.path}>
              <ReactSVG
                src={`/assets/icons/${item.icon}`}
                className="mr-3"
              ></ReactSVG>
              <span className="item-name">
                {i18next.t(`menu:${item.name}`)}
              </span>
            </Link>
            {this.state.activeElement === i && (
              <span className="active-bottom"></span>
            )}
          </li>
        );
      }
    });

  render() {
    return (
      <ul className="childNav side-nav">
        {this.renderLevels(this.props.menu)}
      </ul>
    );
  }
}

export default DropDownMenu;
