import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const ObjectStorage = lazy(() => import("./ObjectStorage"));
const BucketCreate = lazy(() => import("./buckets/BucketCreate"));
const CreateStorageKey = lazy(() =>
  import("./storagekeys/createstoragekey/CreateStorageKey")
);
const FileManager = lazy(() => import("./buckets/filemanager/FileManager"));

const pagesRoutes = [
  {
    path: "/objectstorage/list",
    component: ObjectStorage,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_read"],
  },
  {
    path: "/objectstorage/create",
    component: BucketCreate,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_write"],
  },
  {
    path: "/objectstorage/storagekeys/create",
    component: CreateStorageKey,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_write"],
  },
  {
    path: "/objectstorage/buckets/:project_id/:physical_region_id/:bucket_name/filemanager",
    component: FileManager,
    auth: authRoles.admin,
    required_permissions: ["infrastructure_read"],
  },
];

export default pagesRoutes;
